body,
html {
  overscroll-behavior: none;
  overflow-x: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: geometricPrecision;
}

.w-webflow-badge {
  display: none !important;
}

.loader {
  pointer-events: none;
}
.loaderwrapper {
  overflow: hidden;
}

#burger-close {
  path {
    &:first-child {
      transition: opacity 100ms ease-out;
      opacity: 0;
    }
  }
}
#burger-menu {
  path {
    &:first-child {
      transition: opacity 100ms ease-out;
    }
  }
  &:hover {
    path {
      &:first-child {
        opacity: 0;
      }
    }
  }
}
html {
  width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  -webkit-tap-highlight-color: transparent;
}

.pointer-events-none {
  pointer-events: none;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
  [data-lenis-prevent] {
    overscroll-behavior: contain;
  }
}
html.lenis {
  height: auto;
}
.lenis.lenis-stopped {
  overflow: hidden;
}
// .transition-fade {
//   transition: 0.4s;
//   opacity: 1;
// }
// html.is-animating {
//   .transition-fade {
//     opacity: 0;
//   }
// }
.menutop {
  transition: all 500ms cubic-bezier(0.25, 1, 0.5, 1);
}
.menutop.hide {
  opacity: 0;
  transform: translateY(-50px);
}
.theme-black {
  .logo-nav,
  .burger-open {
    filter: invert(1);
  }
  .link-nav-base {
    color: rgba(0, 0, 0, 0.5);
    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
}

.burger-open {
  transform: scale(0);
  transition: all 500ms cubic-bezier(0.25, 1, 0.5, 1);
  opacity: 0;
  &.show {
    transform: scale(1);
    opacity: 1;
  }
  @media only screen and (max-width: 768px) {
    transform: scale(1) !important;
    opacity: 1 !important;
  }
}

.right-nav-mobile .cta {
  transform: translateX(50px);
  transition: all 500ms cubic-bezier(0.25, 1, 0.5, 1);
  &.translate--back {
    transform: translateX(0px);
  }
  @media only screen and (max-width: 768px) {
    transform: translateX(0) !important;
  }
}

// .transition-fade {
//   transition: 0.2s;
//   opacity: 1;
// }

// html.is-animating .transition-fade {
//   opacity: 0;
// }

a.w--current {
  color: #fff;
}

.pointernone,
.marquee {
  pointer-events: none !important;
}

.success {
  position: absolute;
  bottom: -30px;
  left: 0;
}

.link-block-menu a {
  opacity: 1;
  transition: opacity 300ms ease-out;
}
.link-block-menu a:hover {
  opacity: 0.5;
}

.image-2 {
  aspect-ratio: 580 / 80;
}

.card-actu {
  * {
    z-index: 1;
  }
  .lozange-button {
    z-index: 2;
  }
  a {
    z-index: 5;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0;
    height: 50%;
    background-color: #fff;
    z-index: 0;
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: transform 500ms cubic-bezier(0.25, 1, 0.5, 1);
  }
  .bot-card,
  .small-text,
  .body-15px---light.white,
  .body-15px---semibold {
    transition: color 500ms cubic-bezier(0.25, 1, 0.5, 1);
  }
  &:hover {
    &:after {
      transform: scaleY(1);
    }
    .bot-card {
      .body-15px---light.white,
      .body-15px---semibold {
        color: black !important;
      }
    }
    .small-text {
      color: black;
    }
  }
}

.actu-section {
  .card-actu {
    &:after {
      background-color: #000;
    }
    .bot-card {
      .body-15px---light.white,
      .body-15px---semibold {
        color: black !important;
      }
    }
    .small-text {
      color: black;
    }
    &:hover {
      .bot-card {
        .body-15px---light.white,
        .body-15px---semibold {
          color: white !important;
        }
      }
      .small-text {
        color: white;
      }
    }
  }
}

// .success-stories-wrapper {
//   .w-dyn-item {
//     .top-card-success-stories {
//       &:after {
//         content: "";
//         position: absolute;
//         top: 0%;
//         width: 100%;
//         left: 0;
//         height: 100%;
//         background-color: #fff;
//         z-index: 0;
//         transform: scaleY(0);
//         transform-origin: 100% 100%;
//         transition: transform 500ms cubic-bezier(0.25, 1, 0.5, 1);
//       }
//       .top-left-success-stories {
//         position: relative;
//         z-index: 1;
//         transition: filter 500ms cubic-bezier(0.25, 1, 0.5, 1);
//       }
//     }
//     &:hover {
//       .top-card-success-stories {
//         &:after {
//           transform: scaleY(1);
//         }
//         .top-left-success-stories {
//           filter: invert(1);
//         }
//       }
//     }
//   }
// }

.rich-text-block p, .rich-text-block li {
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
}

.w-richtext-figure-type-image,
.w-richtext figure {
  max-width: 100% !important;
  margin: 80px 0;
}

@media screen and (max-width: 768px) {
  .w-richtext-figure-type-image,
  .w-richtext figure {
    margin: 60px 0;
  }
}

.w-richtext figure > div {
  width: 100%;
}

.w-richtext figure > img {
  width: 100%;
  object-fit: contain;
}

.w-richtext figure > figcaption {
  opacity: 0.5;
  margin-top: 10px;
  text-align: center;
}

// .rich-text-block h2,
// .rich-text-block p {
//   margin-bottom: 80px;
// }

// @media screen and (max-width: 768px) {
//   .rich-text-block h2,
//   .rich-text-block p {
//     margin-bottom: 60px;
//   }
// }

.w-richtext blockquote {
  font-family: "Canela web", sans-serif;
  font-size: 27px;
  font-weight: bold;
  line-height: 37px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 30px 20px 0px 100px;
  border-left: none;
}

@media screen and (max-width: 768px) {
  .w-richtext blockquote {
    padding: 30px 20px 0px 20px;
  }
}

.w-richtext blockquote sup {
  font-size: 180%;
  top: 10px;
  left: -10px;
}

.w-richtext blockquote + p {
  font-size: 10px;
  text-align: right;
  margin-bottom: 80px;
}

@media screen and (min-width: 1024px) {
  .collection-item:nth-child(1),
  .collection-item:nth-child(4),
  .collection-item:nth-child(6) {
    width: 40%;
  }
  .collection-item:nth-child(2),
  .collection-item:nth-child(3),
  .collection-item:nth-child(5) {
    width: 60%;
  }
}

.anim-hero {
  position: relative;
  height: 100vh;

  .wrapper-hero {
    position: relative;
  }
}

.top-card-about {
  .inner-container-blog {
    transition: opacity 500ms cubic-bezier(0.25, 1, 0.5, 1),
      top 500ms cubic-bezier(0.25, 1, 0.5, 1);
    opacity: 0;
    display: flex !important;
    top: 20px;
  }
  &:hover {
    .inner-container-blog {
      opacity: 1;
      z-index: 12;
      top: 0;
    }
  }
}

.image-absolute {
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.w-richtext figure img {
  object-fit: contain !important;
}

.collection-list-blog {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1024px) {
  .collection-item-blog:nth-child(1) {
    width: 60%;
    height: 100%;
  }

  .collection-item-blog:nth-child(2) {
    width: 40%;
    height: 60vh;
  }

  .collection-item-blog:nth-child(4) {
    width: 60%;
    height: 100%;
  }

  .collection-item-blog:nth-child(3) {
    width: 40%;
    height: 60vh;
  }

  .collection-item-blog:nth-child(5) {
    width: 60%;
    height: 100%;
  }

  .collection-item-blog:nth-child(6) {
    width: 40%;
    height: 60vh;
  }

  .collection-item-blog:nth-child(7) {
    width: 60%;
    height: 100%;
  }

  .collection-item-blog:nth-child(8) {
    width: 40%;
    height: 60vh;
  }
}

.wrapper-hero {
  .w-richtext {
    p {
      margin-bottom: 0;
    }
  }
}

.top-content-text-menu {
  transition: color 500ms cubic-bezier(0.25, 1, 0.5, 1);
}
.top-content-text-menu a:hover {
  color: grey;
  cursor: pointer;
}

.theme-black .link-nav-base.w--current {
  color: #000 !important;
}


.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: #000;
  z-index: 9999;
  pointer-events: none;
  svg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    &.logo-left {
      margin-top: -10px;
      opacity: 0;
    }
    &.logo-right {
      margin-top: 10px;
      opacity: 0;
    }
  }
}