body, html {
  overscroll-behavior: none;
  overflow-x: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: geometricprecision;
}

.w-webflow-badge {
  display: none !important;
}

.loader {
  pointer-events: none;
}

.loaderwrapper {
  overflow: hidden;
}

#burger-close path:first-child {
  opacity: 0;
  transition: opacity .1s ease-out;
}

#burger-menu path:first-child {
  transition: opacity .1s ease-out;
}

#burger-menu:hover path:first-child {
  opacity: 0;
}

html {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

html.lenis {
  height: auto;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.menutop {
  transition: all .5s cubic-bezier(.25, 1, .5, 1);
}

.menutop.hide {
  opacity: 0;
  transform: translateY(-50px);
}

.theme-black .logo-nav, .theme-black .burger-open {
  filter: invert();
}

.theme-black .link-nav-base {
  color: #00000080;
}

.theme-black .link-nav-base:hover {
  color: #000;
}

.burger-open {
  opacity: 0;
  transition: all .5s cubic-bezier(.25, 1, .5, 1);
  transform: scale(0);
}

.burger-open.show {
  opacity: 1;
  transform: scale(1);
}

@media only screen and (max-width: 768px) {
  .burger-open {
    opacity: 1 !important;
    transform: scale(1) !important;
  }
}

.right-nav-mobile .cta {
  transition: all .5s cubic-bezier(.25, 1, .5, 1);
  transform: translateX(50px);
}

.right-nav-mobile .cta.translate--back {
  transform: translateX(0);
}

@media only screen and (max-width: 768px) {
  .right-nav-mobile .cta {
    transform: translateX(0) !important;
  }
}

a.w--current {
  color: #fff;
}

.pointernone, .marquee {
  pointer-events: none !important;
}

.success {
  position: absolute;
  bottom: -30px;
  left: 0;
}

.link-block-menu a {
  opacity: 1;
  transition: opacity .3s ease-out;
}

.link-block-menu a:hover {
  opacity: .5;
}

.image-2 {
  aspect-ratio: 580 / 80;
}

.card-actu * {
  z-index: 1;
}

.card-actu .lozange-button {
  z-index: 2;
}

.card-actu a {
  z-index: 5;
}

.card-actu:after {
  content: "";
  width: 100%;
  height: 50%;
  z-index: 0;
  transform-origin: 0 0;
  background-color: #fff;
  transition: transform .5s cubic-bezier(.25, 1, .5, 1);
  position: absolute;
  top: 50%;
  left: 0;
  transform: scaleY(0);
}

.card-actu .bot-card, .card-actu .small-text, .card-actu .body-15px---light.white, .card-actu .body-15px---semibold {
  transition: color .5s cubic-bezier(.25, 1, .5, 1);
}

.card-actu:hover:after {
  transform: scaleY(1);
}

.card-actu:hover .bot-card .body-15px---light.white, .card-actu:hover .bot-card .body-15px---semibold {
  color: #000 !important;
}

.card-actu:hover .small-text {
  color: #000;
}

.actu-section .card-actu:after {
  background-color: #000;
}

.actu-section .card-actu .bot-card .body-15px---light.white, .actu-section .card-actu .bot-card .body-15px---semibold {
  color: #000 !important;
}

.actu-section .card-actu .small-text {
  color: #000;
}

.actu-section .card-actu:hover .bot-card .body-15px---light.white, .actu-section .card-actu:hover .bot-card .body-15px---semibold {
  color: #fff !important;
}

.actu-section .card-actu:hover .small-text {
  color: #fff;
}

.rich-text-block p, .rich-text-block li {
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
}

.w-richtext-figure-type-image, .w-richtext figure {
  margin: 80px 0;
  max-width: 100% !important;
}

@media screen and (max-width: 768px) {
  .w-richtext-figure-type-image, .w-richtext figure {
    margin: 60px 0;
  }
}

.w-richtext figure > div {
  width: 100%;
}

.w-richtext figure > img {
  width: 100%;
  object-fit: contain;
}

.w-richtext figure > figcaption {
  opacity: .5;
  text-align: center;
  margin-top: 10px;
}

.w-richtext blockquote {
  border-top: 1px solid #0003;
  border-left: none;
  padding: 30px 20px 0 100px;
  font-family: Canela web, sans-serif;
  font-size: 27px;
  font-weight: bold;
  line-height: 37px;
}

@media screen and (max-width: 768px) {
  .w-richtext blockquote {
    padding: 30px 20px 0;
  }
}

.w-richtext blockquote sup {
  font-size: 180%;
  top: 10px;
  left: -10px;
}

.w-richtext blockquote + p {
  text-align: right;
  margin-bottom: 80px;
  font-size: 10px;
}

@media screen and (min-width: 1024px) {
  .collection-item:nth-child(1), .collection-item:nth-child(4), .collection-item:nth-child(6) {
    width: 40%;
  }

  .collection-item:nth-child(2), .collection-item:nth-child(3), .collection-item:nth-child(5) {
    width: 60%;
  }
}

.anim-hero {
  height: 100vh;
  position: relative;
}

.anim-hero .wrapper-hero {
  position: relative;
}

.top-card-about .inner-container-blog {
  opacity: 0;
  transition: opacity .5s cubic-bezier(.25, 1, .5, 1), top .5s cubic-bezier(.25, 1, .5, 1);
  top: 20px;
  display: flex !important;
}

.top-card-about:hover .inner-container-blog {
  opacity: 1;
  z-index: 12;
  top: 0;
}

.image-absolute img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.w-richtext figure img {
  object-fit: contain !important;
}

.collection-list-blog {
  flex-wrap: wrap;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .collection-item-blog:nth-child(1) {
    width: 60%;
    height: 100%;
  }

  .collection-item-blog:nth-child(2) {
    width: 40%;
    height: 60vh;
  }

  .collection-item-blog:nth-child(4) {
    width: 60%;
    height: 100%;
  }

  .collection-item-blog:nth-child(3) {
    width: 40%;
    height: 60vh;
  }

  .collection-item-blog:nth-child(5) {
    width: 60%;
    height: 100%;
  }

  .collection-item-blog:nth-child(6) {
    width: 40%;
    height: 60vh;
  }

  .collection-item-blog:nth-child(7) {
    width: 60%;
    height: 100%;
  }

  .collection-item-blog:nth-child(8) {
    width: 40%;
    height: 60vh;
  }
}

.wrapper-hero .w-richtext p {
  margin-bottom: 0;
}

.top-content-text-menu {
  transition: color .5s cubic-bezier(.25, 1, .5, 1);
}

.top-content-text-menu a:hover {
  color: gray;
  cursor: pointer;
}

.theme-black .link-nav-base.w--current {
  color: #000 !important;
}

.preloader {
  width: 100vw;
  height: 100dvh;
  z-index: 9999;
  pointer-events: none;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.preloader svg {
  width: 100px;
  height: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preloader svg.logo-left {
  opacity: 0;
  margin-top: -10px;
}

.preloader svg.logo-right {
  opacity: 0;
  margin-top: 10px;
}

/*# sourceMappingURL=style.css.map */
